<template>
    <div>
        <b-modal
            ref="modalRef"
            title="Dataset Choice"
            hide-footer
        >
            <div class="d-flex flex-column">
                <div class="text-center">
                    For this paired difference CI select either a dataset which
                    consists of differences, or select two datasets and let the
                    app compute the differences
                </div>
                <button
                    class="action-btn w-50 mx-auto"
                    @click="showSelectOneModal"
                >
                    Select 1 Dataset
                </button>
                <div class="text-center">
                    OR
                </div>
                <button
                    class="action-btn w-50 mx-auto"
                    @click="showSelectTwoModal"
                >
                    Select 2 Datasets
                </button>
            </div>
        </b-modal>
        <SelectDatasetModal
            ref="modalSelectOne"
            :min-sample-size="minSampleSize"
            :filter="selectOneFilter"
            :error-message="selectOneErrorMessage"
            @select="selectDataset"
        />
        <SelectTwoDatasetsModal
            ref="modalSelectTwo"
            :min-sample-size="minSampleSize"
            :filter-first="selectTwoFilterFirstOverride"
            :verify="selectTwoVerify"
            :error-message="selectTwoErrorMessage"
            :filter-second="selectTwoFilterSecondOverride"
            @select="calculatePairedDifference"
        />
    </div>
</template>

<script>
import sData from '@/js/summarizeData/summarize-data.js'
import localStore from '@/store/localStore.js'
import _ from 'lodash'
import SelectDatasetModal from './select-dataset-modal.vue'
import SelectTwoDatasetsModal from './select-two-datasets-modal.vue'

export default {
    name: 'PairedDifferenceModal',
    components: {
        SelectDatasetModal,
        SelectTwoDatasetsModal,
    },
    props: {
        minSampleSize: {
            type: Number,
            default: undefined,
        },
        selectOneFilter: {
            type: Function,
            default: () => true,
        },
        selectOneErrorMessage: {
            type: String,
            default: undefined,
        },
        selectTwoFilterFirst: {
            type: Function,
            default: () => true,
        },
        selectTwoVerify: {
            type: Function,
            default: () => true,
        },
        selectTwoErrorMessage: {
            type: String,
            default: undefined,
        },
        selectTwoFilterSecond: {
            type: Function,
            default: () => true,
        },
    },
    computed: {
        getMultipleOccurringSampleSizes () {
            const sizes = []
            const datasets = localStore.getStore('summarize-data')?.data
            if (datasets) {
                datasets.forEach((dataset) => {
                    const size = this.sampleSize(dataset)
                    if (
                        !sizes.includes(size) &&
                        _.filter(datasets, (dataset) => {
                            return this.sampleSize(dataset) === size
                        }).length > 1
                    ) {
                        sizes.push(size)
                    }
                })
            }
            return sizes
        },
    },
    methods: {
        show () {
            this.$refs.modalRef.show()
        },
        hide () {
            this.$refs.modalRef.hide()
        },
        showSelectOneModal () {
            this.$refs.modalSelectOne.show()
            this.hide()
        },
        showSelectTwoModal () {
            this.$refs.modalSelectTwo.show()
            this.hide()
        },
        sampleSize (dataset) {
            return sData.getDataValues(dataset.items).length
        },
        selectTwoFilterFirstOverride (dataset) {
            return this.selectTwoFilterFirst(dataset) &&
                this.getMultipleOccurringSampleSizes.includes(
                    this.sampleSize(dataset)
                )
        },
        selectTwoFilterSecondOverride (dataset, first) {
            return this.selectTwoFilterSecond(dataset, first) &&
                this.sampleSize(dataset) === this.sampleSize(first)
        },
        makeDataset (values, index = 0) {
            const items = []
            for (const i in values) {
                items.push({ index: i, value: values[i] })
            }
            return { index: index, items: items }
        },
        calculatePairedDifference (first, second) {
            first = sData.getDataValues(first.items)
            second = sData.getDataValues(second.items)
            const difference = []
            for (const i in first) {
                difference.push(first[i] - second[i])
            }
            this.selectDataset(this.makeDataset(difference))
        },
        selectDataset (dataset) {
            this.$emit('select', dataset)
        },
    },
}
</script>
